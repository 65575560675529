import Container from "@material-ui/core/Container"
import React from "react"
import { makeStyles } from "@material-ui/core"
import { Typography } from "@material-ui/core/index"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  clinicImage: {
    minWidth: "80%",
    ["@media (max-width:600px)"]: {
      minWidth: "100%",
    },
  },
  missionContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8F4F5",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 !important",
  },
  missionHeader: {
    color: "#9D989A",
    padding: "6em 0",
    maxWidth: 400,
  },
  missionHeaderText: {
    ["@media (max-width:600px)"]: {
      padding: "0px 20px",
      fontSize: "1.7rem",
    },
  },
  missionIngressContainer: {
    justifyContent: "center",
    color: "#9D989A",
    alignSelf: "center",
    alignItems: "center",
    maxWidth: "400px",
  },
  missionIngress: {
    color: "#9D989A",
    paddingBottom: "6em",
  },
}))

const ClinicImageSection = ({ intl, img }) => {
  const classes = useStyles()
  return (
    <Container
      maxWidth="xl"
      component="main"
      className={classes.missionContainer}
    >
      <Container
        maxWidth="xl"
        component="main"
        className={classes.missionHeader}
      >
        <Typography
          align="center"
          variant="h4"
          className={classes.missionHeaderText}
        >
          {intl.formatMessage({ id: "home.ClinicImagesTitle" })}
        </Typography>
      </Container>
      <Img
        fluid={img}
        alt="Clinic building"
        className={classes.clinicImage}
      ></Img>
    </Container>
  )
}

export default injectIntl(ClinicImageSection)
