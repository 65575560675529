import React from "react"
import { makeStyles } from "@material-ui/core/styles/index"
import {
  Container,
  Typography,
  Grid,
  CardMedia,
  Card,
  CardContent,
} from "@material-ui/core/index"
import { Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const name = (person) => `${person.firstName} ${person.lastName}`

const useStyles = makeStyles((theme) => ({
  people: {
    paddingTop: "2em",
    paddingBottom: "3em",
    backgroundColor: "#F8F4F5!important",
  },
  peopleInnerContainer: {
    maxWidth: "70%",
    ["@media (max-width:1600px)"]: {
      maxWidth: "80%",
    },
  },
  personCard: {
    color: "#ADA4A9",
    cursor: "pointer",
    width: "238px",
    // minWidth: "100%",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.7,
      // opacity: "rgba(0,0,0,0.6)",
      // background: "rgba(0,0,0,0.6)",
    },
  },
  personImg: {
    width: "238px",
    height: "340px",
    // minWidth: "100%",
  },
  personContent: {
    backgroundColor: "#E5E3DE",
    // backgroundColor: "#F8F4F5",
    // height: "92px",
    height: 132,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}))

const People = ({ intl, img1, img2, img3 }) => {
  const locale = intl.locale

  const getUrl = (locale, url) => {
    if (locale === "nl") {
      return "/" + url
    }
    return `${locale}/${url}`
  }

  const people = [
    {
      firstName: "Callum",
      lastName: "Faris",
      alt: "Doctor Callum Faris",
      title: intl.formatMessage({ id: "people.MDFacialPlasticSurgeon" }),
      image: img1,
      id: "callum",
    },
    {
      firstName: "Susan",
      lastName: "Schmidt",
      alt: "Doctor Susan Schmidt",
      title: intl.formatMessage({ id: "people.MDCosmeticDoctorKNMG" }),
      image: img2,
      id: "susan",
    },
    {
      firstName: "Hade",
      lastName: "Vuyk",
      alt: "Doctor Hade Vuyk",
      title: intl.formatMessage({ id: "people.MDPhDFacialPlasticSurgeon" }),
      image: img3,
      id: "hade",
    },
  ]
  const classes = useStyles()
  return (
    // <Link to="/about#hade" className={classes.link}>
    // <Link to={"/about#" + people.id} className={classes.link}>
    <Link to={getUrl(locale, "about/")} className={classes.link}>
      <Container maxWidth="xl" component="main" className={classes.people}>
        <Container className={classes.peopleInnerContainer}>
          <Grid container spacing={3} justify="space-evenly">
            {people.map((person) => (
              <Grid item key={person.firstName}>
                <Card className={classes.personCard}>
                  {/* <CardMedia
                    className={classes.personImg}
                    image={person.image}
                    title={name(person)}
                  /> */}
                  <Img fluid={person.image} alt={person.alt}></Img>
                  <CardContent className={classes.personContent}>
                    <Typography
                      align="center"
                      component="h3"
                      variant="subtitle1"
                    >
                      {name(person)}
                    </Typography>
                    <Typography align="center" variant="subtitle2">
                      {person.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
    </Link>
  )
}

export default injectIntl(People)
