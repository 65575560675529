import React from "react"
import { graphql } from "gatsby"
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles"
import MainToolbar from "../components/main-toolbar"
import TheArtTitle from "../components/the-art-title"
import Services from "../components/Cards/servicesCard"
import People from "../components/people"
import Mission from "../components/mission"
import WeAre from "../components/weAre"
// import backgroundImage from "../images/HeroBanner5.jpg"
// import backgroundImage from "../images/HeroBanner10.jpg"
import ClinicImageSection from "../components/ClinicImgageSection"
import backgroundImage from "../images/HeroBanner6.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  background: {
    padding: 0,
    // background: `#333 url(${backgroundImage}) 50% 0 no-repeat`,
    // background: `#F8F4F5 url(${backgroundImage}) 50% 0 no-repeat`,
    background: `#333 url(${backgroundImage}) 50% 0 no-repeat`,
    backgroundPosition: "right",
    backgroundSize: "cover",
    // backgroundSize: "contain",
    // height: 600,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("xl")]: {
      minHeight: "1000px",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "80% 50%",
    },
  },
  backgroundImg: {
    padding: 0,
    backgroundPosition: "right",
    backgroundSize: "cover",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("xl")]: {
      minHeight: "1000px",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "80% 50%",
    },
  },
}))

const Frontpage = ({ intl, data, location }) => {
  const classes = useStyles()
  const bannerImgSources = [
    data.bannerImageMobile.childImageSharp.fluid,
    {
      ...data.bannerImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const clinicImg = data.clinic.childImageSharp.fluid
  const callum = data.callum.childImageSharp.fluid
  const susan = data.susan.childImageSharp.fluid
  const hade = data.hade.childImageSharp.fluid

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "home.SEOtitle" })}
        description={intl.formatMessage({ id: "home.SEO" })}
        path={location.pathname}
      />
      <BackgroundImage
        fluid={bannerImgSources}
        className={classes.backgroundImg}
        backgroundColor={`#333`}
        style={{ backgroundPosition: "right" }}
      >
        {/* <Container maxWidth="xl" className={classes.background}> */}
        <MainToolbar />
        <TheArtTitle />
        <Services />
        {/* </Container> */}
      </BackgroundImage>
      {/* <Services /> */}
      {/* <MiddleTitle /> */}
      <ClinicImageSection img={clinicImg} />
      <WeAre />
      <People img1={callum} img2={susan} img3={hade} />
      <Mission />
      <CookieConsent
        location="bottom"
        buttonText="Accept cookies"
        cookieName="faceInstituteCookie"
        // acceptOnScroll={true}
        // acceptOnScrollPercentage={30}
        style={{
          background: "rgb(25,25,25,0.2)",
          justifyContent: "flex-start",
          fontSize: 16,
        }}
        contentStyle={{ flex: "0 0 300px" }}
        // onAccept={() => {
        //   alert("consent given")
        // }}
        buttonStyle={{
          color: "#97857C",
          backgroundColor: "#FFE7E0",
          padding: "8px 22px",
          fontSize: "14px",
          borderRadius: "20px",
          marginRight: 40,
        }}
        expires={150}
      >
        This website uses cookies:{" "}
        <Link
          style={{ color: "#FFE7E0", textDecoration: "none" }}
          to="/privacy-policy"
        >
          privacy policy
        </Link>
      </CookieConsent>
    </Layout>
  )
}

export default injectIntl(Frontpage)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "HeroBanner6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bannerImageMobile: file(relativePath: { eq: "HeroBanner62.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clinic: file(relativePath: { eq: "bussum-clinic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    callum: file(relativePath: { eq: "CallumFarisWeb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    susan: file(relativePath: { eq: "SusanSchmidtWeb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hade: file(relativePath: { eq: "hadev4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger

// Classic media query
// ['@media (max-width:780px)']: {
//   width: '90%',
// },
